import {debounce as _debounce} from 'lodash';
import * as Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSmoothScroll from 'vue3-smooth-scroll';
import DNationalMap from '@components/organisms/DNationalMap.vue';
import DCallMeFormNational from '@components/organisms/DCallMeFormNational.vue';
import {VueReCaptcha} from 'vue-recaptcha-v3';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


$(window).on('load', async () => {

    Vue.createApp({components: {DNationalMap}}).use(VueSmoothScroll).use(VueAxios, axios).mount('.d-regional-geography__map');
    const dChooseLang = $('.d-choice-language');
    dChooseLang.on('click', function(e) {
        $(this).toggleClass('_open');
    });

    if (document.querySelector('.d-still-questions__inner[data-mod="national"]')) {
        Vue.createApp({components: {DCallMeFormNational}}).use(VueAxios, axios).use(VueReCaptcha, window.recaptchaOptions).mount('.d-still-questions__inner');
    }

    let swiper = null;
    const initSlider = function() {
        if (!swiper && window.matchMedia('(max-width: 767px)').matches) {

            const $utpSlider = $('[data-regional-utp-slider]');
            const $utpSlides = $utpSlider.find('.swiper-slide');
            const utpSlidesCount = $utpSlides.length;

            swiper = new Swiper($utpSlider[0], {
                loop: utpSlidesCount > 2,
                slidesPerView: 1,
                spaceBetween: 12,
                breakpoints: {
                    320: {
                        slidesPerView: 'auto',
                        spaceBetween: 12,
                        loop: true,
                    },
                },
                on: {
                    destroy: function() {
                        swiper = null;
                    },
                },
            });
            $utpSlider.height($utpSlider.height());
        }
        else if (swiper && window.matchMedia('(min-width: 768px)').matches) {
            swiper.destroy();
        }
    };

    const initSliderDebounced = _debounce(initSlider, 500);

    initSlider();
    window.addEventListener('resize', initSliderDebounced);

    $('.d-national-project').each(function(i, el) {
        const slidesCount = $(el).find('.swiper-slide').length;
        if (slidesCount < 2) $(el).addClass('_empty');
        const projectId = el.id;
        new Swiper($(el).find('.swiper').get(0), {
            preloadImages: false,
            lazy: true,
            loop: slidesCount > 1,
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
                nextEl: `.${ projectId }._next`,
                prevEl: `.${ projectId }._prev`,
            },
            breakpoints: {
                320: {
                    spaceBetween: 12,
                    lazy: {
                        loadPrevNext: true,
                    },
                },
                768: {
                    spaceBetween: 0,
                },
                1280: {
                    lazy: {
                        loadPrevNext: false,
                    },
                },
            },
        });
    });

    const $body = $('body');
    const $header = $('[data-header]');
    const $geoMap = $('[data-geography]');

    window.closeMap = (e, timeout = false) => {
        if (e) e.preventDefault();
        $geoMap.removeClass('_show');
        if(timeout) setTimeout(() => {$header.removeClass('_hide')}, 1000)
        else $header.removeClass('_hide')
        document.body.style.overflow = 'initial';
    };

    $body.on('click', '[data-geography-show]', function(e) {
        e.preventDefault();
        $geoMap.addClass('_show');
        $header.addClass('_hide');
        document.body.style.overflow = 'hidden';
    });
    $body.on('click', '[data-geography-close]', (e) => {window.closeMap(e);});
});
